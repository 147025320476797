import {Container, ContentCustom, DangKyNhap, ModalCustom, RowCustom, SliderCustom, StyleUser} from "./style";
import moment from 'moment/moment';
import 'moment-timezone';
import LOGO from '../../Image/logo/logo.png'
import {FaAngleLeft, FaFacebook, FaGuilded, FaHome, FaKey, FaUserCircle} from "react-icons/fa";
import {MdContentCopy, MdDisabledByDefault, MdReplay,} from "react-icons/md";
import copy from 'copy-to-clipboard';
import {message} from "antd";
import BANNER_IMAGE_1 from '../../Image/banner/banner1.jpg'
import BANNER_IMAGE_2 from '../../Image/banner/banner2.jpg'
import BANNER_IMAGE_3 from '../../Image/banner/bannersxmb.png'
import BANNER_IMAGE_4 from '../../Image/banner/banner4.jpg'
import BANNER_IMAGE_5 from '../../Image/banner/banner5.jpg'
import BANNER_IMAGE_6 from '../../Image/banner/banner_digmaan.jpg'
import BANNER_IMAGE_8 from '../../Image/banner/banner8.png'

import IMAGE_BONG_DA_SABA from '../../Image/main/sport_saba.png'
import IMAGE_BONG_DA_DB from '../../Image/main/sport_db.png'
import IMAGE_ESPORT from '../../Image/main/esport_db.png'
import IMAGE_BONG_DA_FB from '../../Image/main/sport_fb.png'
import IMAGE_DIGMAAN_1 from '../../Image/main/daga.jpg'
import IMAGE_FISH from '../../Image/main/fish.png'
import LOGO_CASINO1 from '../../Image/content/ag_casino.png'
import LOGO_CARD_GAME from '../../Image/content/logov8.png'

import BANNER_CASINO_SEXY from '../../Image/main/casino_ag.png'
import BANNER_CASINO_DB from '../../Image/main/casino_db.png'
import BANNER_CASINO_DG from '../../Image/main/casino_dg.png'

import BANNER_GAME_BAI from '../../Image/content/banner_bai.jpg'


import IMAGE_HOT from '../../Image/content/hot.png'
import IMAGE_AVATAR from '../../Image/avatar_default.jpg'
import IMAGE_NAP_TIEN from '../../Image/icon/nap_tien.png'
import IMAGE_RUT_TIEN from '../../Image/icon/rut_tien.png'
import IMAGE_CHUYEN_QUY from '../../Image/icon/chuyen_quy.png'
import IMAGE_CHANGE_PASS from '../../Image/icon/icon_change_pass.png'
import IMAGE_GUIDELINE from '../../Image/icon/guidelines.png'
import IMAGE_LICH_SU_CHOI from '../../Image/icon/lich_su_choi.png'
import IMAGE_LOGOUT from '../../Image/icon/logout.png'
import CHUYEN_DOI from '../../Image/icon/transfer.png'
import ZALO from '../../Image/icon/ic_zalo.png'
import Button from "@mui/material/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ICON_BONG_DA from '../../Image/content/bong_da.png'
import ICON_CASINO from '../../Image/content/icon_casino.png'
import ICON_DIGMAAN from '../../Image/content/logo_digmaan.jpg'
import React, {useEffect, useState} from "react";
import {InputPasswordText, InputText} from "../../shared/components/Input/inputStyles";
import {SelectOption} from "../../shared/components/Select/selectStyle";
import axios from "axios";
import md5 from 'md5';
import {NotificationPopup} from "../../shared/components/NotificationPopup";
import {ERROR, SUCCESS} from "../../shared/components/NotificationPopup/constants";
import DATA from './MOCK_DATA.json'
import {
    BottomNavigation,
    BottomNavigationAction,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    Paper,
    Toolbar
} from "@mui/material";
import {NumericFormat} from "react-number-format";
import GlobalBackdrop from "../../GlobalBackdrop";
import IMAGE_XO_SO from '../../Image/main/xsmb.png'
import LOGO_XO_SO from '../../Image/content/xo_so.png'
import Typography from "@mui/material/Typography";
import PopupQcXoSo from "../../Component/PopupQCXoSo";
import AlertDialog from "../../Component/AlertDialog";
import PopupGame from "../../Component/PopupGame";

const LIST_BANNER = [BANNER_IMAGE_8, BANNER_IMAGE_4, BANNER_IMAGE_3, BANNER_IMAGE_1, BANNER_IMAGE_5, BANNER_IMAGE_6]

const URL = window.SystemConfig.URL

const LIST_CASINO = [
    {
        id: "O2",
        phi: "1.5",
        img: BANNER_CASINO_DB,
        logo: LOGO_CASINO1,
        hot: false,
        enable: false
    },
    {
        id: "S6",
        phi: "1.5",
        img: BANNER_CASINO_SEXY,
        logo: LOGO_CASINO1,
        hot: false,
        enable: true
    },
    {
        id: "DG",
        phi: "1.5",
        img: BANNER_CASINO_DG,
        logo: LOGO_CASINO1,
        hot: false,
        enable: true
    },
]

const LIST_GAME_BAI = [
    {
        id: "casino1",
        phi: "1.5",
        img: BANNER_GAME_BAI,
        logo: LOGO_CARD_GAME,
        hot: false,
        enable: false
    },
]


const LIST_XO_SO = [
    {
        id: "casino1",
        phi: "1.5",
        img: IMAGE_XO_SO,
        logo: LOGO_XO_SO,
        hot: false,
        enable: true
    },
]

const LIST_BONG_DA = [
    {
        id: "IB",
        phi: "1.5",
        img: IMAGE_BONG_DA_SABA,
        title: "THỂ THAO SABA",
        hot: false,
        enable: true
    },
    {
        id: "O1",
        phi: "1.5",
        img: IMAGE_BONG_DA_DB,
        title: "THỂ THAO SABA",
        hot: false,
        enable: true
    },
    {
        id: "FB",
        phi: "1.5",
        img: IMAGE_BONG_DA_FB,
        title: "THỂ THAO SABA",
        hot: false,
        enable: true
    },
]

const LIST_DIGMAAN = [
    {
        id: "O6",
        phi: "1.5",
        img: IMAGE_ESPORT,
        title: "ĐÁ GÀ",
        hot: false,
        enable: false
    },
    {
        id: "DM",
        phi: "1.5",
        img: IMAGE_DIGMAAN_1,
        title: "ĐÁ GÀ",
        hot: false,
        enable: true
    },
    {
        id: "SG",
        phi: "1.5",
        img: IMAGE_FISH,
        title: "ĐÁ GÀ",
        hot: false,
        enable: true
    },

]

function addCommasToNumber(number) {
    if (number === null || number === undefined) {
        return 0;
    }
    if (number.toString().includes(".")) {
        return number.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}


const ComponentRutTien = ({onClose}) => {

    const [tienChinh, setTienChinh] = useState(null)
    const [tienRut, setTienRut] = useState(null)
    const [valueNguoiNhan, setValueNguoiNhan] = useState("nhacai1")
    const [valueNoiDung, setValueNoiDung] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openAlertDialog, setOpenAlertDialog] = useState(false)
    useEffect(() => {
        setTienChinh(localStorage.getItem("balanceMain") || 0)
        onFundAll();
    }, [])
    useEffect(() => {
        setLoading(true)
        axios.get(`${URL}c=3006&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}`)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    if (res.data.data !== "") {
                        setValueNoiDung(res.data.data)
                    }
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }, [])
    const onRutTien = () => {
        setLoading(true)
        axios.get(`${URL}c=2008&nn=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&nnr=${valueNguoiNhan}&rs=${valueNoiDung}&amount=${tienRut / 1}`)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    NotificationPopup("Rút tiền thành công", SUCCESS)
                    setTienChinh(res.data.data)
                    localStorage.setItem("balanceMain", res.data.data)
                } else {
                    NotificationPopup("Rút tiền lỗi", ERROR)
                }
            })
            .catch((error) => {
                setLoading(false)
                NotificationPopup("Chuyển lỗi", ERROR)
                console.log(error)
            })
    }

    const onFundAll = () => {
        setLoading(true)
        axios.get(`${URL}c=3009&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}`)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    setTienChinh(res.data.data.balanceMain)
                    localStorage.setItem("balanceMain", res.data.data.balanceMain)
                } else {
                    NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                }
            })
            .catch((error) => {
                setLoading(false)
                NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                console.log(error)
            })
    }

    return (
        <ModalCustom>
            <ContentCustom>
                <RowCustom>
                    {loading && <GlobalBackdrop/>}
                    <MdDisabledByDefault
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                            color: "red",
                            fontSize: "40px",
                            cursor: "pointer"
                        }}/>
                    <div style={{textAlign: "center", margin: "32px 0", fontWeight: "bold"}}>RÚT TIỀN</div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            marginBottom: "10px"
                        }}>
                            <div style={{
                                width: "100px",
                                fontWeight: "bold",
                                textAlign: "left",
                            }}>Ví chính:
                            </div>
                            <div>{addCommasToNumber(localStorage.getItem("balanceMain") || 0)}</div>
                        </div>
                    </div>
                    {/*<div style={{display: "flex", alignItems: "center", margin: "20px 0"}}>*/}
                    {/*    <div style={{width: "130px", fontWeight: "bold"}}>Người nhận</div>*/}
                    {/*    <InputText*/}
                    {/*        onChange={(e) => {*/}
                    {/*            setValueNguoiNhan(e.target.value)*/}
                    {/*        }}*/}
                    {/*        value={valueNguoiNhan}*/}
                    {/*        max={tienChinh}*/}
                    {/*        style={{width: "100%"}}/>*/}
                    {/*</div>*/}
                    {/*<div style={{color: "red", fontStyle: "italic", marginLeft: "90px"}}>Người nhận phải là đại lý*/}
                    {/*</div>*/}
                    <div style={{display: "flex", alignItems: "center", margin: "20px 0"}}>
                        <div style={{width: "130px", fontWeight: "bold"}}>Số tiền</div>
                        <NumericFormat
                            customInput={InputText}
                            value={tienRut / 1}
                            allowLeadingZeros
                            thousandSeparator=","
                            onValueChange={(values, sourceInfo) => {
                                console.log(values, sourceInfo);
                                setTienRut(values.value)
                            }}
                            style={{width: "206px"}}
                        />
                        <div onClick={() => {
                            setTienRut(tienChinh)
                        }} style={{
                            width: "84px",
                            cursor: "pointer",
                            textAlign: "center",
                            borderRadius: "12px",
                            marginLeft: "8px",
                            padding: "4px",
                            border: "1px solid #ccc"
                        }}>
                            Tất cả
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", margin: "20px 0"}}>
                        <div style={{width: "130px", fontWeight: "bold"}}>Nội dung</div>
                        <InputText
                            onChange={(e) => {
                                setValueNoiDung(e.target.value)
                            }}
                            value={valueNoiDung}
                            max={tienChinh}
                            style={{width: "100%"}}
                            placeholder="Tên bank - STK - Họ tên"/>
                    </div>
                    <div style={{
                        height: "42px",
                        color: "red",
                        fontStyle: "italic",
                        textAlign: "center"
                    }}>
                        {Number(tienRut) < 50000 && "Số tiền chuyển phải lớn hơn 50,000. "}
                        {Number(tienRut) > Number(tienChinh) && "Số tiền bạn muốn rút đang lớn hơn số tiền bạn có."}
                    </div>

                    <Button
                        size={"large"}
                        variant="contained"
                        onClick={onRutTien}
                        disabled={loading || Number(tienRut) > Number(tienChinh) || Number(tienRut) < 50000 || valueNguoiNhan === null || valueNguoiNhan === ""}
                        style={{width: "100%", marginBottom: "20px", overflow: "hidden"}} type="primary"
                    >
                        Rút tiền
                    </Button>
                    <div style={{
                        height: "52px",
                        color: "red",
                        textAlign: "center"
                    }}>
                        Nội dung phải đủ "Tên Bank - Số tài khoản - Họ Tên". Nếu thiếu sẽ không thể rút tiền
                    </div>
                </RowCustom>
                {openAlertDialog &&
                    <AlertDialog title={"Thông báo"} content={"Bạn có muốn chuyển tất cả tiền về ví chính không?"}
                                 onConfirm={() => {
                                     setOpenAlertDialog(false);
                                     onFundAll();
                                 }}
                                 onCancel={() => {
                                     setOpenAlertDialog(false);
                                 }}
                    >

                    </AlertDialog>}

            </ContentCustom>
        </ModalCustom>
    )
}


const ComponentDeposit = ({onClose}) => {
    return (
        <Dialog open={true}>
            <>
                <div style={{padding: "20px 30px", color: "#000", fontWeight: "400"}}>

                    <div style={{
                        textAlign: "center",
                        marginBottom: "8px",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "8px",
                        position: "relative",
                        fontWeight: "bold"
                    }}>
                        Nạp tiền
                    </div>
                    <MdDisabledByDefault
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "0px",
                            top: "0px",
                            color: "red",
                            fontSize: "40px",
                            cursor: "pointer"
                        }}/>

                    <div style={{marginTop: "20px"}}>
                        <FormControl sx={{width: "100%"}}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{marginRight: "6px", width: "140px"}}>Tên ngân hàng
                                    </div>
                                    <div style={{fontWeight: "bold"}}>{"SHINHAN BANK"}</div>
                                    <MdContentCopy onClick={() => {
                                        copy("SHINHAN BANK");
                                        message.success("Copy thành công", [2])
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{marginRight: "6px", width: "140px"}}>Số Tài khoản:
                                    </div>
                                    <div style={{fontWeight: "bold"}}>{"110 315 312 457"}</div>
                                    <MdContentCopy onClick={() => {
                                        copy("110 315 312 457");
                                        message.success("Copy thành công", [2])
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "20px 0",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{marginRight: "6px", width: "140px"}}>Tên tài khoản:</div>
                                    <div style={{fontWeight: "bold"}}>{"DAO THANH HUNG"}</div>
                                    <MdContentCopy onClick={() => {
                                        copy(navigator.clipboard.writeText("DAO THANH HUNG"))
                                        message.success("Copy thành công", [2])
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                            </div>

                        </FormControl>
                        <div style={{
                            height: "42px",
                            color: "red",
                            fontStyle: "italic",
                            textAlign: "center",
                            marginTop: '20px'
                        }}>
                            {"Chú ý: Nội dung chuyển khoản phải là tên đăng nhập của bạn."}
                        </div>
                        <Button
                            size={"large"}
                            variant="contained"
                            onClick={() => {
                                window.open("https://inchonkorea.com/download/inchon_nap.mp4", '_blank')
                            }}
                            style={{width: "100%", marginBottom: "20px", overflow: "hidden"}} type="primary">
                            Bấm để xem hướng dẫn nạp
                        </Button>
                    </div>
                </div>
            </>
        </Dialog>
    )
}

const ComponentChuyenQuy = ({onClose}) => {
    const [listVi, setListVi] = useState([])
    const [viChinh, setViChinh] = useState([{label: `Loading...`, value: "balanceMain"}])
    const [statusChange, setStatusChange] = useState(false)
    const [productType, setProductType] = useState(null)
    const [tienNap, setTienNap] = useState(null)
    const [tienChinh, setTienChinh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [listCoin, setListCoin] = useState(null)


    useEffect(() => {
        onResetData()
    }, [])

    const onResetData = () => {
        setTienChinh(localStorage.getItem("balanceMain") || 0)
        setViChinh([
            {label: `Ví chính : ${addCommasToNumber(localStorage.getItem("balanceMain") || 0)}`, value: "balanceMain"},
        ])
        setListVi([
            {label: `Ví Live Casino : ${addCommasToNumber(localStorage.getItem("balanceLive") || 0)}`, value: "4"},
            {label: `Ví Thể Thao : ${addCommasToNumber(localStorage.getItem("balanceSport") || 0)}`, value: "137"},
            {label: `Ví Đá Gà : ${addCommasToNumber(localStorage.getItem("balanceDigmaan") || 0)}`, value: "138"}
        ])
        setListCoin({
            "4": localStorage.getItem("balanceLive") || 0,
            "137": localStorage.getItem("balanceSport") || 0,
            "138": localStorage.getItem("balanceDigmaan") || 0
        })
    }

    const onChuyenTien = () => {
        setLoading(true)
        axios.get(`${URL}c=2001&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&productType=${productType}&fundType=${statusChange ? 1 : 0}&amount=${tienNap}`)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    setTienChinh(res.data.data.balanceMain)
                    setViChinh([
                        {label: `Ví chính : ${res.data.data.balanceMain}`, value: "balanceMain"},
                    ])
                    localStorage.setItem("balanceMain", res.data.data.balanceMain)
                    if (res.data.data.balanceLive >= 0) {
                        localStorage.setItem("balanceLive", res.data.data.balanceLive)
                    }
                    if (res.data.data.balanceSport >= 0) {
                        localStorage.setItem("balanceSport", res.data.data.balanceSport)
                    }
                    if (res.data.data.balanceDigmaan >= 0) {
                        localStorage.setItem("balanceDigmaan", res.data.data.balanceDigmaan)
                    }

                    setTienNap(0)
                    NotificationPopup("Chuyển thành công", SUCCESS)

                    axios.get(`${URL}c=3010&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}`)
                        .then((res) => {
                            if (res.data.success) {
                                localStorage.setItem("balanceMain", res.data.data.balanceMain)
                                if (res.data.data.balanceLive >= 0) {
                                    localStorage.setItem("balanceLive", res.data.data.balanceLive)
                                }
                                if (res.data.data.balanceSport >= 0) {
                                    localStorage.setItem("balanceSport", res.data.data.balanceSport)
                                }
                                if (res.data.data.balanceDigmaan >= 0) {
                                    localStorage.setItem("balanceDigmaan", res.data.data.balanceDigmaan)
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    onResetData()


                } else {
                    NotificationPopup("Chuyển lỗi", ERROR)
                    setLoading(false)
                }
            })
            .catch((error) => {
                NotificationPopup("Chuyển lỗi", ERROR)
                console.log(error)
                setLoading(false)
            })
    }

    return (
        <ModalCustom>
            <ContentCustom>
                {loading && <GlobalBackdrop/>}
                <RowCustom>
                    <MdDisabledByDefault
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                            color: "red",
                            fontSize: "40px",
                            cursor: "pointer"
                        }}/>
                    <div style={{textAlign: "center", margin: "32px 0"}}>CHUYỂN QUỸ</div>
                    {!statusChange ? <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                            <div style={{
                                width: "100px",
                                fontWeight: "bold",
                                textAlign: "left"
                            }}>{"Từ ví"}</div>
                            <InputText
                                value={`Ví chính : ${addCommasToNumber(localStorage.getItem("balanceMain")) || 0}`}/>
                        </div>
                        <img onClick={() => {
                            setStatusChange(!statusChange)
                            setTienNap(0)
                        }} style={{width: "24px", cursor: "pointer"}} src={CHUYEN_DOI} alt=""/>
                        <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                            <div style={{
                                width: "100px",
                                fontWeight: "bold",
                                textAlign: "left"
                            }}>{"Đến ví"}</div>
                            <SelectOption value={productType}
                                          onChange={(e) => {
                                              setProductType(e)
                                          }} placeholder={"Chọn ví"}
                                          options={listVi}/>
                        </div>
                    </div> : <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                            <div style={{
                                width: "100px",
                                fontWeight: "bold",
                                textAlign: "left"
                            }}>{"Từ ví"}</div>
                            <SelectOption value={productType}
                                          onChange={(e) => {
                                              setProductType(e)
                                          }} placeholder={"Chọn ví"}
                                          options={listVi}/>
                        </div>
                        <img onClick={() => {
                            setStatusChange(!statusChange)
                            setTienNap(0)
                        }} style={{width: "24px", cursor: "pointer"}} src={CHUYEN_DOI} alt=""/>
                        <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                            <div style={{
                                width: "100px",
                                fontWeight: "bold",
                                textAlign: "left"
                            }}>{"Đến ví"}</div>
                            <InputText
                                value={`Ví chính : ${addCommasToNumber(localStorage.getItem("balanceMain")) || 0}`}/>
                        </div>
                    </div>}
                    <div style={{display: "flex", alignItems: "center", margin: "20px 0"}}>
                        <div style={{width: "100px", fontWeight: "bold"}}>Số tiền</div>
                        <NumericFormat
                            customInput={InputText}
                            value={tienNap / 1}
                            allowLeadingZeros
                            thousandSeparator=","
                            onValueChange={(values, sourceInfo) => {
                                setTienNap(values.value)
                                const re = /^[0-9\b]+$/;
                                if (values.value === '' || re.test(values.value)) {
                                    setTienNap(values.value)
                                    if (values.value < 1000) {
                                        setError("Số tiền phải lớn hơn 1000")
                                    } else if (statusChange) {
                                        console.log(productType)
                                        if (values.value > Number(listCoin[productType])) {
                                            setError("Số tiền nạp lớn hơn số tiền có")
                                        } else {
                                            setError(null)
                                        }
                                    } else {
                                        if (values.value > Number(tienChinh)) {
                                            setError("Số tiền nạp lớn hơn số tiền có")
                                        } else {
                                            setError(null)
                                        }
                                    }
                                }
                            }}
                            style={{width: "206px"}}
                        />
                        <div
                            onClick={() => {
                                if (statusChange) {
                                    setTienNap(Number(listCoin[productType]))
                                } else {
                                    setTienNap(tienChinh)
                                }
                            }}
                            style={{
                                cursor: "pointer",
                                width: "80px",
                                textAlign: "center",
                                borderRadius: "12px",
                                marginLeft: "8px",
                                padding: "4px",
                                border: "1px solid #ccc"
                            }}>
                            Tất cả
                        </div>
                    </div>

                    <div style={{height: "32px", width: "100%", color: "red"}}>
                        {error !== null && error}
                    </div>

                    {/*<Button variant="contained"*/}
                    {/*        size={"large"}*/}
                    {/*        disabled={loading || productType === null || tienNap === null || tienNap === 0 || tienNap === "" || (statusChange === false && tienNap < 1000)}*/}
                    {/*        onClick={onChuyenTien}*/}
                    {/*        style={{width: "100%", marginBottom: "20px", overflow: "hidden"}} type="primary">Chuyển*/}
                    {/*    quỹ</Button>*/}

                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    size={"large"}*/}
                    {/*    onClick={() => {*/}
                    {/*    }}*/}
                    {/*    style={{width: "100%", overflow: "hidden"}} type="primary">Nạp tiền</Button>*/}
                </RowCustom>
            </ContentCustom>
        </ModalCustom>
    )
}

const ComponentLogin = ({onClose, onClickDangKy, data, onLoginSuccess}) => {
    const [value, setValue] = useState({
        un: null,
        pw: null
    })

    useEffect(() => {
        if (data) {
            setValue({
                un: data.username,
                pw: data.password
            })
        }
    }, [data])

    const [error, setError] = useState(null)
    const onDangNhap = () => {
        if (value.un === null || value.pw === null || value.un === "" || value.pw === "") {
            setError("Tài khoản và mật khẩu không được để chống")
        } else {
            axios.get(`${URL}c=3&un=${value.un.toLocaleLowerCase()}&pw=${md5(value.pw)}`)
                .then((res) => {
                    if (res.data.success) {
                        localStorage.setItem("at", res.data.accessToken)
                        localStorage.setItem("un", value.un.toLocaleLowerCase())
                        setError(null)
                        onLoginSuccess()
                        onClose()
                    } else {
                        setError("Tài khoản hoặc mật khẩu không đúng")
                    }
                })
                .catch((error) => {
                    setError("Hệ thống đang lỗi, thử lại sau")
                    console.log(error)
                })
        }
    }
    return (
        <DangKyNhap>
            <div className="header">
                <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>
                    <img style={{height: "70px", margin: "5px 0"}} src={LOGO} alt=""/>
                    <span style={{
                        marginTop: '20px',
                        fontSize: '25px',
                        marginLeft: '10px',
                        color: '#e5ae6d',
                        fontWeight: '900'
                    }}>KOREA 뉴스</span>
                </div>
                <div style={{position: "absolute", left: "0", top: "20px", fontSize: "32px", cursor: "pointer"}}>
                    <FaAngleLeft onClick={onClose} style={{color: "#528ded"}}/>
                </div>
            </div>

            <div className="row_login">
                <div className={"title"}>
                    Đăng Nhập
                </div>
                <div style={{display: "flex", alignItems: "center", alignContent: "center", margin: "20px 0"}}>
                    <div style={{minWidth: "120px"}}>Tên Đăng Nhập</div>
                    <InputText
                        value={value.un}
                        onChange={(e) => {
                            let object = {...value}
                            setValue({...object, un: e.target.value})
                        }}
                        placeholder="Nhập tên đăng nhập"
                        addonBefore={<FaUserCircle/>}/>
                </div>

                <div style={{display: "flex", alignItems: "center", alignContent: 'center', margin: "20px 0"}}>
                    <div style={{minWidth: "120px"}}>Mật khẩu</div>
                    <InputPasswordText
                        value={value.pw}
                        onChange={(e) => {
                            let object = {...value}
                            setValue({...object, pw: e.target.value})
                        }}
                        placeholder="Nhập mật khẩu"
                        addonBefore={<FaKey/>}/>
                </div>
                <div style={{height: "32px", width: "100%", color: "red"}}>
                    {error !== null && error}
                </div>
                <Button
                    variant="contained"
                    sx={{backgroundColor: '#0d9403', color: "white", width: "100%"}}
                    size={"large"}
                    onClick={onDangNhap}>
                    Đăng nhập</Button>
                <Button
                    size={"large"}
                    sx={{marginTop: "20px"}}
                    onClick={onClickDangKy}>
                    Đăng ký
                </Button>
            </div>
        </DangKyNhap>
    )
}

const ComponentDangKy = ({onClose, onDangKySuccess, onDangNhap}) => {
    const [value, setValue] = useState({
        un: null,
        pw: null,
        rePw: null,
        refer: "nhacai1"
    })
    const [error, setError] = useState(null)

    const onDangKy = () => {
        setError(null)
        if (value.un === null ||
            value.pw === null ||
            value.un === "" ||
            value.pw === "" ||
            value.refer === "" ||
            value.refer === null ||
            value.rePw === "" ||
            value.rePw === null) {
            setError("Tài khoản và mật khẩu không được để chống")
        } else if (value.un.length < 3 || value.un.length > 10) {
            setError("Tên đăng nhập từ 3 đến 10 ký tự")
        } else if (value.pw !== value.rePw) {
            setError("Mật khẩu không khớp")
        } else {
            axios.get(`${URL}c=2006&username=${value.un.toLocaleLowerCase()}&password=${md5(value.pw)}&refer=${value.refer}`)
                .then((res) => {
                    if (res.data.success) {
                        // localStorage.setItem("at", res.data.accessToken)
                        // localStorage.setItem("un", value.un)
                        onDangKySuccess({username: value.un, password: value.pw})
                        setError(null)
                        // onClose()
                        NotificationPopup('Đăng ký tài khoản thành công ', SUCCESS);
                    } else {
                        if (res.data.errorCode === '1006')
                            setError("Tài khoản đã tồn tại. vui lòng chọn tên đăng nhập khác");
                        else if (res.data.errorCode === '116' || res.data.errorCode === '150')
                            setError("Tên tài khoản là các ký tự từ A->Z và số từ 0-9. Tên tài khoản tối thiểu 3 ký tự và tối đa 10 ký tự");
                        else
                            setError("Tạo tài khoản không thành công. Vui lòng kiểm tra lại thông tin và thử lại.");
                    }
                })
                .catch((error) => {
                    setError("Tạo tài khoản không thành công. Vui lòng kiểm tra lại thông tin và thử lại.")
                    console.log(error)
                })
        }
    }
    return (
        <DangKyNhap>
            <div className="header">
                <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>
                    <img style={{height: "70px", margin: "5px 0"}} src={LOGO} alt=""/>
                    <span style={{
                        marginTop: '20px',
                        fontSize: '25px',
                        marginLeft: '10px',
                        color: '#e5ae6d',
                        fontWeight: '900'
                    }}>KOREA 뉴스</span>
                </div>
                <div style={{position: "absolute", left: "0", top: "20px", fontSize: "32px", cursor: "pointer"}}>
                    <FaAngleLeft onClick={onClose} style={{color: "#528ded"}}/>
                </div>
            </div>

            <div className="row_login">
                <div className={"title"}>
                    Đăng Ký
                </div>
                <div style={{display: "flex", alignItems: "center", alignContent: "center", margin: "20px 0"}}>
                    <div style={{minWidth: "120px"}}>Tên Đăng Nhập</div>
                    <InputText value={value.un} onChange={(e) => {
                        let object = {...value}
                        setValue({...object, un: e.target.value})
                    }} placeholder="Nhập tên đăng nhập" addonBefore={<FaUserCircle/>}/>
                </div>
                <div style={{display: "flex", alignItems: "center", alignContent: 'center', margin: "20px 0"}}>
                    <div style={{minWidth: "120px"}}>Mật khẩu</div>
                    <InputPasswordText value={value.pw} onChange={(e) => {
                        let object = {...value}
                        setValue({...object, pw: e.target.value})
                    }} placeholder="Nhập mật khẩu" addonBefore={<FaKey/>}/>
                </div>
                <div style={{display: "flex", alignItems: "center", alignContent: 'center', margin: "20px 0"}}>
                    <div style={{minWidth: "120px"}}>Xác nhận mật khẩu</div>
                    <InputPasswordText value={value.rePw} onChange={(e) => {
                        let object = {...value}
                        setValue({...object, rePw: e.target.value})
                    }} placeholder="Nhập xác nhận mật khẩu" addonBefore={<FaKey/>}/>
                </div>
                {/*<div style={{display: "flex", alignItems: "center", alignContent: "center", margin: "20px 0"}}>*/}
                {/*    <div style={{minWidth: "120px"}}>Đại lý</div>*/}
                {/*    <InputText value={value.refer} onChange={(e) => {*/}
                {/*        let object = {...value}*/}
                {/*        setValue({...object, refer: e.target.value})*/}
                {/*    }} placeholder="Nhập tên đăng nhập" addonBefore={<FaUserCircle/>}/>*/}
                {/*</div>*/}
                <div style={{height: "32px", width: "100%", color: "red"}}>
                    {error !== null && error}
                </div>
                <Button variant="contained" sx={{backgroundColor: '#0d9403', color: "white", width: "100%"}}
                        size={"large"}
                        onClick={onDangKy}
                >Đăng ký</Button>
                <Button
                    onClick={onDangNhap}
                    size={"large"}
                    sx={{marginTop: "20px"}}>
                    Đăng nhập
                </Button>
            </div>
        </DangKyNhap>
    )
}
const UIGuideline = ({onClose}) => {
    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Hướng dẫn"}
            </DialogTitle>
            <DialogContent>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea onClick={() => {
                        window.open("https://inchonkorea.com/download/inchon_huong_dan_nhan_khuyenmai.mp4", '_blank')
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                                Hướng dẫn nhận khuyến mãi
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={BANNER_IMAGE_5}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea onClick={() => {
                        window.open("https://inchonkorea.com/download/inchon_rut.mp4", '_blank')
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                                Hướng dẫn rút
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={BANNER_IMAGE_4}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card>
                <div style={{marginBottom: "10px"}}></div>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea onClick={() => {
                        window.open("https://inchonkorea.com/download/inchon_nap.mp4", '_blank')
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                                Hướng dẫn nạp
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={BANNER_IMAGE_1}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card>
                <div style={{marginBottom: "10px"}}></div>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea onClick={() => {
                        window.open("https://inchonkorea.com/download/inchon_cachchoi.mp4", '_blank')
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                                Hướng dẫn cách chơi thắng nhà cái
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={BANNER_IMAGE_2}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card>
                <Card sx={{maxWidth: 345}}>
                    <CardActionArea onClick={() => {
                        window.open("https://inchonkorea.com/download/inchon_xsmb.mp4", '_blank')
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="text.secondary">
                                Hướng dẫn cách chơi xổ số miền bắc
                            </Typography>
                        </CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={BANNER_IMAGE_3}
                            alt="green iguana"
                        />
                    </CardActionArea>
                </Card>
                <div style={{marginBottom: "10px"}}></div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Đóng</Button>
            </DialogActions>
        </Dialog>
    );
}

const HistoryPlay = ({onClose}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        axios.get(`${URL}c=2007&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&page=1`)
            .then((res) => {
                if (res.data.success) {
                    setLoading(false)
                    console.log(res.data)
                    setData(res.data.transactions)
                } else {
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }, [])
    return (
        <ModalCustom>
            <ContentCustom>
                {loading && <GlobalBackdrop/>}
                <RowCustom>

                    <div style={{
                        textAlign: "center",
                        marginBottom: "8px",
                        borderBottom: "1px solid #ccc",
                        paddingBottom: "8px",
                        position: "relative"
                    }}>
                        Lịch sử chơi
                    </div>
                    <MdDisabledByDefault
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                            color: "red",
                            fontSize: "40px",
                            cursor: "pointer"
                        }}/>

                    <div style={{
                        height: "60vh",
                        overflow: "auto",
                    }}>
                        {data.length > 0 ? data.map((item) => {
                            return (
                                <div style={{
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    width: "92%",
                                    margin: "8px 0",
                                    borderRadius: "6px",
                                    fontSize: "14px",
                                    backgroundColor: "#F5EFE7"
                                }}>
                                    <div style={{marginBottom: "4px"}}>Mã GD: {item.ref_no}</div>
                                    <div
                                        style={{marginBottom: "4px"}}>{moment(item.end_time).format("MM/DD/YYYY hh:mm")}</div>

                                    <div
                                        style={{display: "flex", justifyContent: "space-between", marginBottom: "4px"}}>
                                        <div>Thể loại: {item.product}</div>
                                        <div>Tổng cược: {addCommasToNumber(item.bet)}</div>
                                    </div>

                                    <div
                                        style={{display: "flex", justifyContent: "space-between", marginBottom: "4px"}}>
                                        <div>Kết
                                            quả: {item.payout - item.bet > 0 ? ` +${addCommasToNumber(item.payout - item.bet)}` : addCommasToNumber(item.payout - item.bet)}</div>
                                        <div>Thắng/Thua: {item.payout - item.bet > 0 ?
                                            <span style={{color: "green", fontWeight: "bold"}}>Thắng cược</span> :
                                            item.payout - item.bet === 0 ?
                                                <span style={{color: "gray", fontWeight: "bold"}}>Hòa cược</span> :
                                                <span style={{color: "red", fontWeight: "bold"}}>Thua cược</span>
                                        }</div>
                                    </div>
                                </div>
                            )
                        }) : <div style={{textAlign: "center", marginTop: "20px", fontStyle: "italic"}}>
                            {!loading && "Lịch sử chơi chống"}</div>}
                    </div>
                </RowCustom>
            </ContentCustom>
        </ModalCustom>
    )
}


const HistoryBank = ({onClose}) => {
    const [historyNap, setHistoryNap] = useState([])
    const [historyRut, setHistoryRut] = useState([])
    const [type, setType] = useState("nap")
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        axios.get(`${URL}c=302&nn=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&mt=3&p=1`)
            .then((res) => {
                if (res.data.success) {
                    setLoading(false)
                    console.log(res.data)
                    setHistoryNap(res.data.transactions)
                } else {
                    setLoading(false)
                    NotificationPopup("Lấy dữ liệu không thành công", ERROR)
                }
            })
            .catch((error) => {
                setLoading(false)
                NotificationPopup("Lấy dữ liệu không thành công", ERROR)
                console.log(error)
            })
        axios.get(`${URL}c=302&nn=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&mt=5&p=1`)
            .then((res) => {
                if (res.data.success) {
                    console.log(res.data)
                    setHistoryRut(res.data.transactions)
                } else {
                    NotificationPopup("Lấy dữ liệu không thành công", ERROR)
                }
            })
            .catch((error) => {
                NotificationPopup("Lấy dữ liệu không thành công", ERROR)
                console.log(error)
            })
    }, [])
    return (
        <ModalCustom>
            <ContentCustom>
                {loading && <GlobalBackdrop/>}
                <RowCustom>

                    <div style={{
                        textAlign: "center",
                        marginBottom: "8px",
                        paddingBottom: "8px",
                        position: "relative",
                        fontWeight: "bold",
                    }}>
                        Lịch sử nạp rút
                    </div>
                    <MdDisabledByDefault
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "12px",
                            color: "red",
                            fontSize: "40px",
                            cursor: "pointer"
                        }}/>
                    <div style={{display: "flex", justifyContent: "center", marginBottom: "12px"}}>
                        <Button
                            size={"large"}
                            sx={{margin: "0 8px"}}
                            onClick={() => {
                                setType("nap")
                            }}
                            variant={type === "nap" ? "contained" : "normal"}>Nạp tiền</Button>
                        <Button
                            size={"large"}
                            sx={{margin: "0 8px"}}
                            onClick={() => {
                                setType("rut")
                            }}
                            variant={type === "rut" ? "contained" : "normal"}>Rút tiền</Button>
                    </div>
                    <div style={{borderBottom: "1px solid #ccc",}}></div>

                    {type === "nap" ? (<div style={{
                        height: "60vh",
                        overflow: "auto",
                    }}>
                        {historyNap.length > 0 ? historyNap.map((item) => {
                            return (
                                <div style={{
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    width: "92%",
                                    margin: "8px 0",
                                    borderRadius: "6px",
                                    fontSize: "14px",
                                    backgroundColor: "#F5EFE7",
                                    fontWeight: "bold"
                                }}>
                                    <div style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Mã GD:</span> {item.transId}</div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span
                                            style={{fontWeight: "normal"}}>Ngày GĐ</span>: {moment(item.transactionTime).format("MM/DD/YYYY hh:mm")}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Thể loại:</span> {item.serviceName}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span
                                            style={{fontWeight: "normal"}}>Số tiền:</span> {addCommasToNumber(item.moneyExchange)}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Nội dung chuyển:</span> {item.description}
                                    </div>
                                </div>
                            )
                        }) : <div style={{textAlign: "center", marginTop: "20px", fontStyle: "italic"}}>
                            {!loading && "Lịch sử nạp chống"}</div>}
                    </div>) : <div style={{
                        height: "60vh",
                        overflow: "auto",
                    }}>
                        {historyRut.length > 0 ? historyRut.map((item) => {
                            return (
                                <div style={{
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    width: "92%",
                                    margin: "8px 0",
                                    borderRadius: "6px",
                                    fontSize: "14px",
                                    backgroundColor: "#F5EFE7",
                                    fontWeight: "bold"
                                }}>
                                    <div style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Mã GD:</span> {item.transId}</div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span
                                            style={{fontWeight: "normal"}}>Ngày GĐ</span>: {moment(item.transactionTime).format("MM/DD/YYYY hh:mm")}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Thể loại:</span> {item.serviceName}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span
                                            style={{fontWeight: "normal"}}>Số tiền:</span> {addCommasToNumber(item.moneyExchange)}
                                    </div>
                                    <div
                                        style={{marginBottom: "4px"}}>
                                        <span style={{fontWeight: "normal"}}>Nội dung chuyển:</span> {item.description}
                                    </div>
                                </div>
                            )
                        }) : <div style={{textAlign: "center", marginTop: "20px", fontStyle: "italic"}}>
                            Lịch sử rút chống</div>}
                    </div>}
                </RowCustom>
            </ContentCustom>
        </ModalCustom>
    )
}

function Home() {
    const [menuFocus, setMenuFocus] = useState("home")
    const [listPopup, setListPopup] = useState({
        isDangNhap: false,
        isDangKy: false,
        isChuyenQuy: false,
        isDeposit: false,
        isLichSu: false,
        isChangePass: false,
        isHistoryBank: false,
        isRutTien: false,
        isGuideline: false,
    })
    const [duLieuDangNhap, setDuLieuDangNhap] = useState(null)
    const [loadingBalance, setLoadingBalance] = useState(false)
    const [loadingView, setLoadingView] = useState(false)
    const [isLoadingBalanceAPI, setIsLoadingBalanceAPI] = useState(false)
    const [isPopupQCXsmb, setIsPopupQCXsmb] = useState(true)
    const [linkGame, setLinkGame] = useState(null)

    const onLogout = () => {
        localStorage.clear()
        setMenuFocus("home")
        localStorage.removeItem("time")
        setListPopup({
            isDangNhap: false,
            isDangKy: false,
            isChuyenQuy: false,
            isChangePass: false,
            isLichSu: false,
            isHistoryBank: false,
            isRutTien: false,
            isGuideline: false,
            isDeposit: false,
        })
    }

    const checkKqxs = () => {
        const now = moment();
        const targetTime = moment().set({hour: 18, minute: 10, second: 0, millisecond: 0});
        const isPastTargetTime = now.isAfter(targetTime);
        if (isPastTargetTime) {
            axios.get(`${URL}c=3004&&date=${moment().format('YYYYMMDD')}`)
                .then((res) => {
                    localStorage.setItem("kqxs", res.data.data.resultSp)
                    localStorage.setItem("date", moment(res.data.data.date).format('YYYY-MM-DD'))
                })
                .catch((error) => {
                    setLoadingView(false)
                    console.log(error)
                })
        } else {
            axios.get(`${URL}c=3004&date=${moment().subtract(1, 'days').format('YYYYMMDD')}`)
                .then((res) => {
                    localStorage.setItem("kqxs", res.data.data.resultSp)
                    localStorage.setItem("date", moment(res.data.data.date).format('YYYY-MM-DD'))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        checkKqxs()
    }, [])


    const [countdown, setCountdown] = useState('');

    useEffect(() => {
        const now = moment().tz('Asia/Ho_Chi_Minh'); // Lấy thời gian hiện tại ở múi giờ Việt Nam
        const targetTime = moment().tz('Asia/Ho_Chi_Minh').set({hour: 18, minute: 25, second: 0, millisecond: 0});

        if (now.isAfter(targetTime)) {
            // Thời gian hiện tại đã qua 18:10, tạo bộ đếm ngược đến 18:10 ngày hôm sau
            targetTime.add(1, 'days');
        }

        const interval = 1000; // Cập nhật mỗi giây

        const timer = setInterval(() => {
            const duration = moment.duration(targetTime.diff(moment().tz('Asia/Ho_Chi_Minh')));

            const hours = Math.floor(duration.asHours());
            const minutes = Math.floor(duration.minutes());
            const seconds = Math.floor(duration.seconds());

            setCountdown(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

            if (duration.asMilliseconds() <= 0) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer); // Hủy timer khi component bị hủy
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            var date = new Date()
            var timestamp = date.getTime()
            if (localStorage.getItem("time")) {
                const dataTime = timestamp - localStorage.getItem("time")
                if (dataTime / 1000 / 60 > 30) {
                    NotificationPopup("Phiên đăng nhập đã hết, Vui lòng đăng nhập lại", ERROR)
                    onLogout()
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        };
    }, [])

    useEffect(() => {
        const intervalBalance = setInterval(() => {
            if (localStorage.getItem("un")) {
                checkKqxs()
                loadingCoin(false)
            }
        }, 1000 * 60)
        return () => {
            clearInterval(intervalBalance)
        };
    }, [])


    useEffect(() => {
        var date = new Date()
        var timestamp = date.getTime()
        if (localStorage.getItem("time")) {
            const dataTime = timestamp - localStorage.getItem("time")
            if (dataTime / 1000 / 60 > 30) {
                NotificationPopup("Phiên đăng nhập đã hết, Vui lòng đăng nhập lại", ERROR)
                localStorage.clear()
                onLogout()
            } else {
                updateTimeUsingApp()
            }
        }
        if (localStorage.getItem("un")) {
            setLoadingView(true)
            setIsLoadingBalanceAPI(true)
            loadingCoin(true)
        }
    }, [])

    const loadingCoin = (isFirst = false) => {
        if (!isLoadingBalanceAPI) {
            if (isFirst) {
                setLoadingBalance(true)
            }
            axios.get(`${URL}c=3011&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}`)
                .then((res) => {
                    if (res.data.success) {
                        setIsLoadingBalanceAPI(false)
                        setLoadingBalance(false)
                        setLoadingView(false)
                        localStorage.setItem("balanceMain", res.data.data.balanceMain)
                        // if (res.data.data.balanceLive >= 0) {
                        //     localStorage.setItem("balanceLive", res.data.data.balanceLive)
                        // }
                        // if (res.data.data.balanceSport >= 0) {
                        //     localStorage.setItem("balanceSport", res.data.data.balanceSport)
                        // }
                        // if (res.data.data.balanceDigmaan >= 0) {
                        //     localStorage.setItem("balanceDigmaan", res.data.data.balanceDigmaan)
                        // }
                    } else {
                        setIsLoadingBalanceAPI(false)
                        setLoadingBalance(false)
                        setLoadingView(false)
                    }
                })
                .catch((error) => {
                    setIsLoadingBalanceAPI(false)
                    setLoadingBalance(false)
                    setLoadingView(false)
                    console.log(error)
                })
        }
    }

    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function updateTimeUsingApp() {
        var date = new Date()
        var timestamp = date.getTime()
        localStorage.setItem("time", timestamp)
    }

    function getGameUrl(providerCode) {
        setLoadingView(true)
        axios.get(`${URL}c=2012&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}&providerCode=${providerCode}&platform=mobile`)
            .then((res) => {
                if (res.data.success) {
                    console.log(1111111);
                    setLoadingView(false)
                    setTimeout(() => {
                        // window.open(res.data.data, '_blank')
                        setLoadingView(false)
                        let linkGame = res.data.data;
                        if (linkGame.startsWith('http://')) {
                            linkGame = linkGame.replace('http://', 'https://');
                        }
                        setLinkGame(linkGame)
                    }, 200)
                } else {
                    setLoadingView(false)
                    NotificationPopup(" Game đang bảo trì", SUCCESS)
                }
            })
            .catch((error) => {
                setLoadingView(false)
                console.log(error)
            })
    }


    return (
        <div>
            {linkGame && <PopupGame
                link={linkGame}
                onClose={() => {
                    setLinkGame(null)
                }}
            />}
            {isPopupQCXsmb && <PopupQcXoSo onClose={() => {
                setIsPopupQCXsmb(false)
            }}/>}
            {loadingView && <GlobalBackdrop/>}
            {listPopup.isRutTien && <ComponentRutTien onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isRutTien: false})
            }}/>}
            {listPopup.isLichSu && <HistoryPlay data={DATA} onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isLichSu: false})
            }}/>}
            {listPopup.isHistoryBank && <HistoryBank data={DATA} onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isHistoryBank: false})
            }}/>}
            {listPopup.isGuideline && <UIGuideline onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isGuideline: false})
            }}/>}
            {listPopup.isDangKy &&
                <ComponentDangKy
                    onDangNhap={() => {
                        let object = {...listPopup}
                        setListPopup({...object, isDangKy: false, isDangNhap: true})
                    }}
                    onDangKySuccess={(data) => {
                        setDuLieuDangNhap(data)
                        let object = {...listPopup}
                        setListPopup({...object, isDangNhap: true, isDangKy: false})
                    }}
                    onClose={() => {
                        let object = {...listPopup}
                        setListPopup({...object, isDangKy: false})
                    }}/>}
            {listPopup.isDangNhap &&
                <ComponentLogin
                    onLoginSuccess={() => {
                        setLoadingView(true)
                        loadingCoin()
                        updateTimeUsingApp();
                    }}
                    data={duLieuDangNhap}
                    onClickDangKy={() => {
                        let object = {...listPopup}
                        setListPopup({...object, isDangNhap: false, isDangKy: true})
                    }}
                    onClose={() => {
                        let object = {...listPopup}
                        setListPopup({...object, isDangNhap: false})
                    }}/>}

            {listPopup.isChuyenQuy && <ComponentChuyenQuy onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isChuyenQuy: false})
            }}/>}
            {listPopup.isDeposit && <ComponentDeposit onClose={() => {
                let object = {...listPopup}
                setListPopup({...object, isDeposit: false})
            }}/>}

            <Container>
                {(menuFocus === "home" && listPopup.isDangKy === false && listPopup.isDangNhap === false) && (
                    <>
                        <div className="header">
                            <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>
                                <img style={{height: "50px", margin: "10px 0"}} src={LOGO} alt=""/>
                                <span style={{
                                    marginTop: '15px',
                                    fontSize: '20px',
                                    marginLeft: '10px',
                                    color: '#e5ae6d',
                                    fontWeight: '900'
                                }}>KOREA 뉴스</span>
                            </div>
                            {!localStorage.getItem("un") ? <div className="row_login">
                                <div onClick={() => {
                                    let object = {...listPopup}
                                    setListPopup({...object, isDangNhap: true})
                                }} style={{cursor: "pointer"}}>Đăng Nhập
                                </div>
                                <span style={{margin: "0 8px"}}>|</span>
                                <div onClick={() => {
                                    let object = {...listPopup}
                                    setListPopup({...object, isDangKy: true})
                                }} style={{cursor: "pointer"}}>Đăng Ký
                                </div>
                            </div> : <div style={{fontSize: "14px"}}>
                                <div style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    textTransform: "uppercase"
                                }}>{localStorage.getItem("un")} - {addCommasToNumber(localStorage.getItem("balanceMain") || 0)} đ
                                </div>
                            </div>}
                        </div>

                        <div className="banner">
                            <SliderCustom {...settings}>
                                {LIST_BANNER.map((item, index) => {
                                    return <img src={item}/>
                                })}
                            </SliderCustom>
                        </div>

                        <div className="title_content_game">
                            <div className="header_content_game">
                                <div className="title_content_game">
                                    <img src={ICON_CASINO} alt=""/>
                                    <div>LIVE CASINO</div>
                                </div>
                            </div>
                        </div>

                        <div className="row_casino">
                            {LIST_CASINO.map((item) => {
                                if (!item.enable) {
                                    return (<div></div>)
                                } else
                                    return (
                                        <div className="item_casino" style={{backgroundImage: `url(${item.img})`}}
                                             onClick={() => {
                                                 let object = {...listPopup}
                                                 if (localStorage.getItem("un")) {
                                                     getGameUrl(item.id)
                                                     updateTimeUsingApp()
                                                 } else {
                                                     setListPopup({...object, isDangNhap: true})
                                                 }
                                             }}>
                                            {item.hot &&
                                                <img style={{position: "absolute", right: "0", width: "40px"}}
                                                     src={IMAGE_HOT}
                                                     alt=""/>}
                                        </div>
                                    )
                            })}
                        </div>

                        <div className="title_content_game">
                            <div className="header_content_game">
                                <div className="title_content_game">
                                    <img src={ICON_BONG_DA} alt=""/>
                                    <div>TRẬN CẦU ĐỈNH CAO</div>
                                </div>
                            </div>
                        </div>

                        <div className="row_bong_da">
                            {LIST_BONG_DA.map((item) => {
                                if (!item.enable) {
                                    return (<div></div>)
                                } else
                                    return (
                                        <div className="item_bong_da" style={{backgroundImage: `url(${item.img})`}}
                                             onClick={() => {
                                                 let object = {...listPopup}
                                                 if (localStorage.getItem("un")) {
                                                     getGameUrl(item.id)
                                                     updateTimeUsingApp()
                                                 } else {
                                                     setListPopup({...object, isDangNhap: true})
                                                 }
                                             }}>
                                            {item.hot &&
                                                <img style={{position: "absolute", right: "0", width: "40px"}}
                                                     src={IMAGE_HOT}
                                                     alt=""/>}
                                        </div>
                                    )
                            })}
                        </div>

                        <div className="title_content_game">
                            <div className="header_content_game">
                                <div className="title_content_game">
                                    <img src={ICON_DIGMAAN} alt=""/>
                                    <div>Game Hay</div>
                                </div>
                            </div>
                        </div>

                        <div className="row_casino">
                            {LIST_DIGMAAN.map((item) => {
                                if (!item.enable) {
                                    return (<div></div>)
                                } else
                                    return (
                                        <div className="item_casino" style={{backgroundImage: `url(${item.img})`}}
                                             onClick={() => {
                                                 let object = {...listPopup}
                                                 if (localStorage.getItem("un")) {
                                                     updateTimeUsingApp()
                                                     getGameUrl(item.id)
                                                 } else {
                                                     setListPopup({...object, isDangNhap: true})
                                                 }
                                             }}>
                                            {item.hot &&
                                                <img style={{position: "absolute", right: "0", width: "40px"}}
                                                     src={IMAGE_HOT}
                                                     alt=""/>}
                                        </div>
                                    )
                            })}
                        </div>

                        <div className="title_content_game">
                            <div className="header_content_game">
                                <div className="title_content_game">
                                    <img src={LOGO_XO_SO} alt=""/>
                                    <div>XỐ SỐ MIỀN BẮC</div>
                                </div>
                            </div>
                        </div>

                        <div className="row_casino">
                            {LIST_XO_SO.map((item) => {
                                if (!item.enable) {
                                    return (<div></div>)
                                } else
                                    return (
                                        <div className="item_casino" style={{backgroundImage: `url(${item.img})`}}
                                             onClick={() => {
                                                 window.open('/quay-so', '_blank', 'noopener,noreferrer');
                                             }}>
                                            {item.hot &&
                                                <img style={{position: "absolute", right: "0", width: "40px"}}
                                                     src={IMAGE_HOT}
                                                     alt=""/>}
                                            <div style={{
                                                textAlign: "center",
                                                position: "absolute",
                                                bottom: "40px",
                                                left: "20px",
                                                fontSize: "14px",
                                                display: "flex", alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                paddingRight: "50px"
                                            }}>
                                                <div style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    backgroundColor: "white",
                                                    padding: "4px 12px",
                                                    borderRadius: "12px",
                                                    textAlign: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <div>
                                                        Kết quả
                                                        ngày: {localStorage.getItem("date") ? localStorage.getItem("date") : null}
                                                    </div>
                                                    <div>{localStorage.getItem("kqxs") ?
                                                        <div style={{display: "flex", fontSize: "12px"}}>
                                                            {localStorage.getItem("kqxs") && localStorage.getItem("kqxs").split('').map((item) => {
                                                                return <div
                                                                    style={{
                                                                        height: "24px",
                                                                        width: "24px",
                                                                        lineHeight: "24px",
                                                                        textAlign: "center",
                                                                        borderRadius: "50%",
                                                                        backgroundColor: "green",
                                                                        color: "white"
                                                                    }}>{item}</div>
                                                            })}
                                                        </div> : null}</div>
                                                    <div>{countdown !== null ? countdown : null}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>
                        <div style={{paddingBottom: "72px"}}></div>
                    </>
                )}

                {(menuFocus === "my" && listPopup.isDangKy === false && listPopup.isDangNhap === false && localStorage.getItem('un')) &&
                    <StyleUser>
                        <div className={"header"}>
                            <div className={"row_header"}>
                                <img style={{height: "60px", borderRadius: "50%"}} src={IMAGE_AVATAR} alt=""/>
                                <div style={{
                                    marginLeft: "24px",
                                    color: "white",
                                    fontWeight: "bold",
                                    textTransform: "uppercase"
                                }}>{localStorage.getItem("un")}</div>
                            </div>
                        </div>
                        <div className={"main"}>
                            <div style={{margin: "20px", position: "relative"}}>
                                <div style={{position: "absolute", top: "2px", right: "0px", fontSize: "30px"}}>
                                    <MdReplay onClick={() => {
                                        setLoadingView(true);
                                        axios.get(`${URL}c=3011&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}`)
                                            .then((res) => {
                                                setLoadingView(false);
                                                if (res.data.success) {
                                                    localStorage.setItem("balanceMain", res.data.data.balanceMain)
                                                } else {
                                                    NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                                                }
                                            })
                                            .catch((error) => {
                                                setLoadingView(false);
                                                NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                                                console.log(error)
                                            })
                                    }} style={{color: "black"}}/>
                                </div>
                                <div className={"item_show_coin"} style={{backgroundColor: "#025464", color: "white"}}>
                                    <div>
                                        Số dư
                                    </div>
                                    <div>
                                        {!loadingBalance ? addCommasToNumber(localStorage.getItem("balanceMain") || 0) : 'loading ...'}
                                    </div>
                                </div>
                                {/*                         <div className={"item_show_coin"} style={{backgroundColor: "#E57C23", color: "white"}}>
                                    <div>
                                        Ví Live Casino
                                    </div>
                                    <div>
                                        {!loadingBalance ? addCommasToNumber(localStorage.getItem("balanceLive") || 0) : 'loading ...'}
                                    </div>
                                </div>
                                <div className={"item_show_coin"} style={{backgroundColor: "#E8AA42", color: "white"}}>
                                    <div>
                                        Ví Thể thao
                                    </div>
                                    <div>
                                        {!loadingBalance ? addCommasToNumber(localStorage.getItem("balanceSport") || 0) : 'loading ...'}
                                    </div>
                                </div>
                                <div className={"item_show_coin"} style={{backgroundColor: "#E8AA42", color: "white"}}>
                                    <div>
                                        Ví Đá gà
                                    </div>
                                    <div>
                                        {!loadingBalance ? addCommasToNumber(localStorage.getItem("balanceDigmaan") || 0) : 'loading ...'}
                                    </div>
                                </div>*/}
                                {/*<Button variant="contained" startIcon={<IconWithdrawAll/>}
                                        style={{marginBottom: '12px', borderRadius: '12px', width: 'calc(100% - 40px)'}}
                                        onClick={() => {
                                            axios.get(`${URL}c=3009&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}`)
                                                .then((res) => {
                                                    if (res.data.success) {
                                                        localStorage.setItem("balanceMain", res.data.data.balanceMain)
                                                        if (res.data.data.balanceLive >= 0) {
                                                            localStorage.setItem("balanceLive", res.data.data.balanceLive)
                                                        }
                                                        if (res.data.data.balanceSport >= 0) {
                                                            localStorage.setItem("balanceSport", res.data.data.balanceSport)
                                                        }
                                                        if (res.data.data.balanceDigmaan >= 0) {
                                                            localStorage.setItem("balanceDigmaan", res.data.data.balanceDigmaan)
                                                        }
                                                    } else {
                                                        NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                                                    }
                                                })
                                                .catch((error) => {
                                                    NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                                                    console.log(error)
                                                })
                                        }}>
                                    Chuyển toàn bộ về ví chính
                                </Button>*/}
                                {/*<div className={"item_show_coin"} style={{backgroundColor: "#F8F1F1"}}>*/}
                                {/*    <div>*/}
                                {/*        Ví V8Poker*/}
                                {/*    </div>*/}
                                {/*    <div>*/}
                                {/*        {!loadingBalance ? addCommasToNumber(localStorage.getItem("balanceDigmaan") || 0) : 'loading ...'}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div style={{display: "flex", justifyContent: "space-around", marginTop: "50px"}}>
                                    <div onClick={() => {
                                        updateTimeUsingApp()
                                        let object = {...listPopup}
                                        setListPopup({...object, isDeposit: true})
                                    }} className={"item_button_function"}>
                                        <img src={IMAGE_NAP_TIEN} alt=""/>
                                        <div>Nạp tiền</div>
                                    </div>
                                    <div onClick={() => {
                                        updateTimeUsingApp()
                                        let object = {...listPopup}
                                        setListPopup({...object, isRutTien: true})
                                    }} className={"item_button_function"}>
                                        <img src={IMAGE_RUT_TIEN} alt=""/>
                                        <div>Rút tiền</div>
                                    </div>
                                    {/*<div onClick={() => {
                                        updateTimeUsingApp()
                                        let object = {...listPopup}
                                        setListPopup({...object, isChuyenQuy: true})
                                    }} className={"item_button_function"}>
                                        <img src={IMAGE_CHUYEN_QUY} alt=""/>
                                        <div>Chuyển quỹ</div>
                                    </div>*/}
                                </div>
                                <div onClick={() => {
                                    updateTimeUsingApp()
                                    let object = {...listPopup}
                                    setListPopup({...object, isLichSu: true})
                                }} className="button_chuc_nang">
                                    <img src={IMAGE_LICH_SU_CHOI} alt=""/>
                                    <div>Lịch sử chơi</div>
                                </div>
                                <div
                                    onClick={() => {
                                        updateTimeUsingApp()
                                        let object = {...listPopup}
                                        setListPopup({...object, isHistoryBank: true})
                                    }} className="button_chuc_nang">
                                    <img src={IMAGE_CHUYEN_QUY} alt=""/>
                                    <div>Lịch sử nạp rút</div>
                                </div>
                                <div onClick={() => {
                                    updateTimeUsingApp()
                                    let object = {...listPopup}
                                    setListPopup({...object, isChangePass: true})
                                }} className="button_chuc_nang">
                                    <img src={IMAGE_CHANGE_PASS} alt=""/>
                                    <div>Đổi mật khẩu</div>
                                </div>
                                <div onClick={() => {
                                    updateTimeUsingApp()
                                    let object = {...listPopup}
                                    setListPopup({...object, isGuideline: true})
                                }} className="button_chuc_nang">
                                    <img src={IMAGE_GUIDELINE} alt=""/>
                                    <div>Hướng dẫn nhận tiền</div>
                                </div>
                                <div onClick={() => {
                                    onLogout()
                                }} className="button_chuc_nang">
                                    <img src={IMAGE_LOGOUT} alt=""/>
                                    <div>Đăng xuất</div>
                                </div>
                            </div>

                        </div>
                    </StyleUser>}
                <Toolbar sx={{
                    position: 'fixed',
                    bottom: 0,
                    marginBottom: "60px",
                    display: 'flex',
                    flexDirection: 'row-reverse'
                }}>
                    <Fab color="info" aria-label="add" onClick={() => {
                        updateTimeUsingApp()
                        window.open("https://zaloapp.com/qr/p/1uty1rthlrdts", '_blank')
                    }}>
                        <img style={{
                            width: "55px",
                            height: "55px",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }} src={ZALO} alt=""/>
                    </Fab>
                </Toolbar>
                {/*<Fab sx={{position: 'fixed', bottom: 0, left: 0, right: 0, marginBottom: '100px',marginRight:'30px'}} elevation={4}>
                    <img style={{
                        width: "70px",
                        height: "70px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={ZALO} alt=""/>
                </Fab>*/}

                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <BottomNavigation
                        showLabels
                    >
                        <BottomNavigationAction onClick={() => {
                            updateTimeUsingApp()
                            window.open("https://www.facebook.com/profile.php?id=100093469154923&mibextid=ZbWKwL", '_blank')
                        }} label="Liên hệ" icon={<FaFacebook style={{fontSize: "26px"}}/>}/>
                        <BottomNavigationAction onClick={() => {
                            updateTimeUsingApp()
                            setMenuFocus("home")
                        }} label="Trang chủ" icon={<FaHome style={{fontSize: "26px"}}/>}/>
                        {localStorage.getItem('un') && <BottomNavigationAction onClick={() => {
                            updateTimeUsingApp()
                            setMenuFocus("my")
                        }} label="Của tôi" icon={<FaUserCircle style={{fontSize: "26px"}}/>}/>}
                        <BottomNavigationAction onClick={() => {
                            updateTimeUsingApp()
                            let object = {...listPopup}
                            setListPopup({...object, isGuideline: true})
                        }} label="Hướng dẫn nhận tiền" icon={<FaGuilded style={{fontSize: "26px"}}/>}/>

                    </BottomNavigation>
                </Paper>
            </Container>
        </div>
    );
}

export default Home;
