import React, {useEffect, useState} from 'react';
import axios from "axios";
import moment from 'moment/moment';
import 'moment-timezone';
import {NotificationPopup} from "../../shared/components/NotificationPopup";
import {ERROR} from "../../shared/components/NotificationPopup/constants";
import {Container, InputText} from "./style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import {MdCalendarToday} from "react-icons/md";
import {NumericFormat} from "react-number-format";
import {useNavigate} from "react-router-dom";
import GlobalBackdrop from "../../GlobalBackdrop";
import {message} from "antd";

const URL = window.SystemConfig.URL
const QuaySo = () => {
    const navigate = useNavigate();
    const [game, setGame] = useState("BA_LO")
    const [gameMode, setGameMode] = useState("Lo2So")
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(null)
    const [menu, setMenu] = useState('game')
    const [history, setHistory] = useState([])
    const [response, setResponse] = useState(null)
    const [timeResponse, setTimeResponse] = useState(new Date());
    const [timeHistory, setTimeHistory] = useState(new Date());
    const [timeGame, setTimeGame] = useState(new Date());
    const [quantityNumberBet, setQuantityNumberBet] = useState('')
    const [moneyBet, setMoneyBet] = useState(0)
    const [moneyWin, setMoneyWin] = useState(0)
    const [loading, setLoading] = useState(false)
    const [balanceMain, setBalanceMain] = useState(localStorage.getItem("balanceMain") || '0')
    // const loadingCoin = (isFirst = false) => {
    //     axios.get(`${URL}c=2003&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}`)
    //         .then((res) => {
    //             if (res.data.success) {
    //                 localStorage.setItem("balanceMain", res.data.data.balanceMain)
    //                 setBalanceMain(res.data.data.balanceMain)
    //                 if (res.data.data.balanceLive >= 0) {
    //                     localStorage.setItem("balanceLive", res.data.data.balanceLive)
    //                 }
    //                 if (res.data.data.balanceSport >= 0) {
    //                     localStorage.setItem("balanceSport", res.data.data.balanceSport)
    //                 }
    //                 if (res.data.data.balanceV8 >= 0) {
    //                     localStorage.setItem("balanceV8", res.data.data.balanceV8)
    //                 }
    //             } else {
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    const onLogout = () => {
        localStorage.clear()
        localStorage.removeItem("time")
        navigate('/')
    }


    useEffect(() => {
        if (!localStorage.getItem("un")) {
            navigate('/')
            return;
        }
        const interval = setInterval(() => {
            var date = new Date()
            var timestamp = date.getTime()
            if (localStorage.getItem("time")) {
                const dataTime = timestamp - localStorage.getItem("time")
                setBalanceMain(localStorage.getItem("balanceMain"))
                if (dataTime / 1000 / 60 > 30) {
                    NotificationPopup("Phiên đăng nhập đã hết, Vui lòng đăng nhập lại", ERROR)
                    onLogout()
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        };
    }, [])


    // useEffect(() => {
    //     const intervalBalance = setInterval(() => {
    //         if (localStorage.getItem("un")) {
    //             loadingCoin(false)
    //         }
    //     }, 1000 * 60)
    //     return () => {
    //         clearInterval(intervalBalance)
    //     };
    // }, [])

    const onClickGame = (e) => {
        updateTimeUsingApp();
        setGame(e)
        setQuantityNumberBet('');
        if (e === 'BA_LO') {
            onClickGameMode('Lo2So')
        } else if (e === 'LO_XIEN') {
            onClickGameMode('Xien2')
        } else if (e === 'LO_TRUOT') {
            onClickGameMode('Truot10')
        }
        setInputValue('')
        setError(null)
    }


    const onClickGameMode = (e) => {
        updateTimeUsingApp();
        setGameMode(e)
        setQuantityNumberBet('');
        setInputValue('')
        setError(null)
    }

    function isValidLo2SoAndDe(inputString, pattern) {
        let numbers = inputString.split(pattern);
        for (let i = 0; i < numbers.length; i++) {
            console.log(numbers[i])
            if (numbers[i].length !== 2) {
                console.log(1111111)
                return false;
            }
        }
        return true;
    }

    function isValidSequenceLo3sooAndBacang(inputString) {
        const numbers = inputString.split(', ');

        if (numbers.length === 1) {
            return numbers[0].length === 3;
        } else if (numbers.length > 1) {
            const uniqueNumbers = [...new Set(numbers)];
            console.log(uniqueNumbers)
            return uniqueNumbers.length === numbers.length && uniqueNumbers.every(num => num.length === 3);
        }
        return false;
    }

    function endsWithNumber(str) {
        return /\d$/.test(str);
    }

    function onlyNumber(str) {
        return str.match(/^[0-9]+$/) != null;
    }

    const handleChange = (event) => {
        let value = event.target.value;
        if (value.endsWith("--")) {
            value = value.replace("--", '-')
        }
        if (value.endsWith(",,")) {
            value = value.replace(",,", ',')
        }
        let endValue = value.substring(value.length - 1, value.length);
        if (gameMode === "Lo3So" || gameMode === "BaCang") {
            let sanitizedValue = value.replace(/[^0-9 ,]/g, '');
            if (sanitizedValue.length > 3) {
                if (endsWithNumber(sanitizedValue) && onlyNumber(sanitizedValue.substring(sanitizedValue.length - 4, sanitizedValue.length))) {
                    sanitizedValue = value.substring(0, value.length - 1) + ', ' + endValue;
                }
                if ((sanitizedValue.endsWith("-") || sanitizedValue.endsWith(",")) && !onlyNumber(sanitizedValue.substring(sanitizedValue.length - 4, sanitizedValue.length))) {
                    sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
                }
            }
            setInputValue(sanitizedValue);
        } else if (gameMode === "De" || gameMode === "Lo2So") {

            let sanitizedValue = value.replace(/[^0-9 ,]/g, '');
            if (sanitizedValue.length > 2) {
                if (endsWithNumber(sanitizedValue) && onlyNumber(sanitizedValue.substring(sanitizedValue.length - 3, sanitizedValue.length))) {
                    sanitizedValue = value.substring(0, value.length - 1) + ', ' + endValue;
                }
                if ((sanitizedValue.endsWith("-") || sanitizedValue.endsWith(",")) && !onlyNumber(sanitizedValue.substring(sanitizedValue.length - 3, sanitizedValue.length))) {
                    sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
                }
            } else if (sanitizedValue.length === 3) {
                sanitizedValue = value.substring(0, value.length - 1) + ', ' + endValue;
            }
            setInputValue(sanitizedValue);
        } else {
            let sanitizedValue = event.target.value.replace(/[^0-9 ,-]/g, '');
            let maxNumber = 2;
            if (gameMode === "Xien2") {
                maxNumber = 2;
            } else if (gameMode === "Xien3") {
                maxNumber = 3;
            } else if (gameMode === "Xien4") {
                maxNumber = 4;
            } else if (gameMode === "Xien5") {
                maxNumber = 5;
            } else if (gameMode === "Truot10") {
                maxNumber = 10;
            } else if (gameMode === "Truot12") {
                maxNumber = 12;
            } else if (gameMode === "Truot14") {
                maxNumber = 14;
            } else {
                setError(null)
            }
            if (sanitizedValue.length > 2) {
                if (endsWithNumber(sanitizedValue) && onlyNumber(sanitizedValue.substring(sanitizedValue.length - 3, sanitizedValue.length))) {
                    if (sanitizedValue.length > maxNumber * 3) {
                        let check = sanitizedValue.substring(sanitizedValue.length - (maxNumber * 3 + 2), sanitizedValue.length - (maxNumber * 3 + 1));
                        sanitizedValue = value.substring(0, value.length - 1) + (check === ',' || check === ' ' ? ', ' : '-') + endValue;
                    } else if (sanitizedValue.length === maxNumber * 3) {
                        sanitizedValue = value.substring(0, value.length - 1) + ', ' + endValue;
                    } else {
                        sanitizedValue = value.substring(0, value.length - 1) + '-' + endValue;
                    }
                }
                if ((sanitizedValue.endsWith("-") || sanitizedValue.endsWith(",")) && !onlyNumber(sanitizedValue.substring(sanitizedValue.length - 3, sanitizedValue.length))) {
                    sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
                }
            }
            setInputValue(sanitizedValue);
        }
    };
    const formatInput = (value) => {
        return value;
    };


    useEffect(() => {
        if (menu === "history") {
            setLoading(true)
            axios.get(`${URL}c=3005&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}&date=${moment(timeHistory).format('YYYYMMDD')}`)
                .then((res) => {
                    // console.log(res)
                    setHistory(res.data.data)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error)
                    setHistory([])
                })
        }
        if (menu === "response") {
            setLoading(true)
            axios.get(`${URL}c=3004&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}&date=${moment(timeResponse).format('YYYYMMDD')}`)
                .then((res) => {
                    setLoading(false)
                    setResponse(res.data.data)
                })
                .catch((error) => {
                    setLoading(false)
                    setHistory([])
                    console.log(error)
                })
        }
    }, [menu])


    const RenderStatus = (status) => {
        if (status === 'Pending') {
            return <span style={{color: "#8b9400", fontWeight: "bold"}}>Chờ trả thưởng</span>
        }
        if (status === 'RewardSuccess') {
            return <span style={{color: "green", fontWeight: "bold"}}>Trả thưởng thành công</span>
        }
        return null
    }


    const RenderNameGame = (name) => {
        if (name === "Lo2So") {
            return "Lô 2 số"
        }
        if (name === "Lo3So") {
            return "Lô 3 số"
        }
        if (name === "De") {
            return "Đề Đặc biệt"
        }
        if (name === "BaCang") {
            return "Ba Càng"
        }
        if (name === "Xien2") {
            return "Xiên 2 số"
        }
        if (name === "Xien3") {
            return "Xiên 3 số"
        }
        if (name === "Xien4") {
            return "Xiên 4 số"
        }
        if (name === "Xien5") {
            return "Xiên 5 số"
        }
        if (name === "Truot10") {
            return "Trượt 10 số"
        }
        if (name === "Truot12") {
            return "Trượt 12 số"
        }
        if (name === "Truot14") {
            return "Trượt 14 số"
        }

        return null
    }


    const onSearchResponse = () => {
        setLoading(true)
        axios.get(`${URL}c=3004&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}&date=${moment(timeResponse).format('YYYYMMDD')}`)
            .then((res) => {
                setLoading(false)
                setResponse(res.data.data)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const onSearchHistory = () => {
        setLoading(true)
        axios.get(`${URL}c=3005&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}&date=${moment(timeHistory).format('YYYYMMDD')}`)
            .then((res) => {
                setLoading(false)
                setHistory(res.data.data)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const onChangeQuantityNumberBetNumberBet = (e) => {
        setQuantityNumberBet(e.value)
    }

    useEffect(() => {
        const number = inputValue.split(",").length > 0 ? inputValue.split(",").length : 0
        // console.log("number ====== ")
        // console.log(number)
        // console.log("quantityNumberBet ================")
        // console.log(quantityNumberBet)
        if (game === "DANH_DE") {
            // console.log(window.SystemConfig.CUOC_DE_2_SO * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_DE_2_SO * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_DE_2_SO * quantityNumberBet)
        }
        if (game === "BA_CANG") {
            // console.log(window.SystemConfig.CUOC_DE_2_SO * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_BA_CANG * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_BA_CANG * quantityNumberBet)
        }
        if (gameMode === "Lo2So") {
            // console.log(window.SystemConfig.CUOC_LO_2_SO * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_2_SO * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_2_SO * quantityNumberBet)
        }
        if (gameMode === "Lo3So") {
            // console.log(window.SystemConfig.CUOC_LO_3_SO * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_3_SO * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_3_SO * quantityNumberBet)
        }
        if (gameMode === "Xien2") {
            // console.log(window.SystemConfig.CUOC_LO_XIEN_2 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_XIEN_2 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_XIEN_2 * quantityNumberBet)
        }
        if (gameMode === "Xien3") {
            // console.log(window.SystemConfig.CUOC_LO_XIEN_3 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_XIEN_3 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_XIEN_3 * quantityNumberBet)
        }
        if (gameMode === "Xien4") {
            // console.log(window.SystemConfig.CUOC_LO_XIEN_4 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_XIEN_4 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_XIEN_4 * quantityNumberBet)
        }
        if (gameMode === "Xien5") {
            // console.log(window.SystemConfig.CUOC_LO_XIEN_5 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_XIEN_5 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_XIEN_5 * quantityNumberBet)
        }
        if (gameMode === "Truot10") {
            // console.log(window.SystemConfig.CUOC_LO_TRUOT_10 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_TRUOT_10 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_TRUOT_10 * quantityNumberBet)
        }
        if (gameMode === "Truot12") {
            // console.log(window.SystemConfig.CUOC_LO_TRUOT_12 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_TRUOT_12 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_TRUOT_12 * quantityNumberBet)
        }
        if (gameMode === "Truot14") {
            // console.log(window.SystemConfig.CUOC_LO_TRUOT_14 * quantityNumberBet)
            setMoneyBet(window.SystemConfig.CUOC_LO_TRUOT_14 * quantityNumberBet * number)
            setMoneyWin(window.SystemConfig.KET_QUA_LO_TRUOT_14 * quantityNumberBet)
        }
    }, [inputValue, quantityNumberBet])

    function validateNumber(strOutput) {
        let error = "";
        switch (gameMode) {
            case "Lo3So":
            case "BaCang":
                if (!isValidSequenceLo3sooAndBacang(strOutput)) {
                    error = "Số đặt cược phải có 3 chữ số";
                }
                break;

            case "De":
            case "Lo2So":
                if (!isValidLo2SoAndDe(strOutput, ", ")) {
                    error = "Số đặt cược phải có 2 chữ số";
                }
                break;

            default:
                let maxNumber = 2;
                if (["Xien2", "Xien3", "Xien4", "Xien5", "Truot10", "Truot12", "Truot14"].includes(gameMode)) {
                    maxNumber = parseInt(gameMode.match(/\d+/)[0], 10);
                    let arrNumbers = strOutput.split(", ");

                    for (let i = 0; i < arrNumbers.length; i++) {
                        if (isValidLo2SoAndDe(arrNumbers[i].trim(), "-")) {
                            const number = arrNumbers[i].trim().split("-");
                            if (gameMode.includes("Xien") && number.length !== maxNumber) {
                                error = "Xiên " + maxNumber + " phải cược " + maxNumber + " số";
                            } else if (gameMode.includes("Truot") && number.length !== maxNumber) {
                                error = "Trượt " + maxNumber + " phải cược " + maxNumber + " số";
                            }
                        } else {
                            error = "Số đặt cược phải có 2 chữ số";
                        }
                        if (error !== '') {
                            break;
                        }
                    }
                }
                break;
        }
        return error;

    }


    const onBet = () => {
        updateTimeUsingApp();
        setLoading(true)
        let outputString = inputValue.trim();
        if (!onlyNumber(outputString.substring(outputString.length - 1, outputString.length))) {
            outputString = outputString.substring(0, outputString.length - 1);
        }
        let validNumber = validateNumber(outputString);
        if (validNumber !== '') {
            console.log(validNumber)
            setError(validNumber);
            setLoading(false)
            return;
        }
        if (quantityNumberBet <= 0) {
            setError('số điểm cươợc phải lớn hơn 0');
            setLoading(false)
            return;
        }
        console.log("onBet: true")
        setLoading(false)
        axios.get(`${URL}c=3003&un=${localStorage.getItem("un")}&pw=${localStorage.getItem("pw")}&at=${localStorage.getItem("at")}&date=${moment(timeGame).format('YYYYMMDD')}&gameMode=${gameMode}&gamePoint=${quantityNumberBet}&numbers=${outputString}`)
            .then((res) => {
                if (res.data.success === true) {
                    setError("");
                    setTimeout(() => {
                        message.success("Cược thành công")
                        setLoading(false)
                    }, 500)
                    if (res.data.data.balanceMain) {
                        setBalanceMain(res.data.data.balanceMain)
                        localStorage.setItem("balanceMain", res.data.data.balanceMain)
                    }
                    setInputValue('')
                    setMoneyBet(0)
                    setMoneyWin(0)
                }
                if (res.data.success === false) {
                    setError("");
                    setTimeout(() => {
                        message.error(res.data.data)
                        setLoading(false)
                    }, 500)
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false)
                    message.error("Cược lỗi ! Vui lòng thử lại")
                }, 500)
            })
    }


    const [countdown, setCountdown] = useState('');
    const [countdownDe, setCountdownDeBacang] = useState('');

    useEffect(() => {
        const now = moment().tz('Asia/Ho_Chi_Minh'); // Lấy thời gian hiện tại ở múi giờ Việt Nam
        const targetTime = moment().tz('Asia/Ho_Chi_Minh').set({hour: 18, minute: 5, second: 0, millisecond: 0});

        if (now.isAfter(targetTime)) {
            // Thời gian hiện tại đã qua 18:5, tạo bộ đếm ngược đến 18:5 ngày hôm sau
            targetTime.add(1, 'days');
        }

        const interval = 1000; // Cập nhật mỗi giây

        const timer = setInterval(() => {
            const duration = moment.duration(targetTime.diff(moment().tz('Asia/Ho_Chi_Minh')));

            const hours = Math.floor(duration.asHours());
            const minutes = Math.floor(duration.minutes());
            const seconds = Math.floor(duration.seconds());

            setCountdown(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

            if (duration.asMilliseconds() <= 0) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer); // Hủy timer khi component bị hủy
    }, []);
    useEffect(() => {
        const now = moment().tz('Asia/Ho_Chi_Minh'); // Lấy thời gian hiện tại ở múi giờ Việt Nam
        const targetTime = moment().tz('Asia/Ho_Chi_Minh').set({hour: 18, minute: 25, second: 0, millisecond: 0});

        if (now.isAfter(targetTime)) {
            targetTime.add(1, 'days');
        }

        const interval = 1000; // Cập nhật mỗi giây

        const timer = setInterval(() => {
            const duration = moment.duration(targetTime.diff(moment().tz('Asia/Ho_Chi_Minh')));

            const hours = Math.floor(duration.asHours());
            const minutes = Math.floor(duration.minutes());
            const seconds = Math.floor(duration.seconds());

            setCountdownDeBacang(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

            if (duration.asMilliseconds() <= 0) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer); // Hủy timer khi component bị hủy
    }, []);

    function addThousandSeparator(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        onFundAll();
    }, []);
    const onFundAll = () => {
        setLoading(true)
        axios.get(`${URL}c=3009&un=${localStorage.getItem("un")}&at=${localStorage.getItem("at")}`)
            .then((res) => {
                setLoading(false)
                if (res.data.success) {
                    setBalanceMain(res.data.data.balanceMain)
                    localStorage.setItem("balanceMain", res.data.data.balanceMain)
                } else {
                    NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                }
            })
            .catch((error) => {
                setLoading(false)
                NotificationPopup("Đã có lỗi xảy ra. vui lòng thử lại", ERROR)
                console.log(error)
            })
    }

    return (<Container>
        {loading && <GlobalBackdrop/>}
        <div className="header">
            <div>
                Chào <span style={{color: "blue"}}>{localStorage.getItem('un')}</span>
            </div>

            <div>
                Số dư: <span
                style={{color: "blue"}}>{addThousandSeparator(balanceMain ? balanceMain : 0)} </span>
            </div>
        </div>

        <div className="menu">
            <div className="row_menu">
                <div className="item_menu"
                     onClick={() => {
                         setMenu("game")
                     }}
                     style={{borderBottom: menu === "game" ? "1px solid #000" : "1px solid #ffb600"}}>
                    ĐÁNH ĐỀ
                </div>
                <div className="item_menu"
                     onClick={() => {
                         setMenu("history")
                     }}
                     style={{borderBottom: menu === "history" ? "1px solid #000" : "1px solid #ffb600"}}>
                    LỊCH SỬ
                </div>
                <div className="item_menu"
                     onClick={() => {
                         setMenu("response")
                     }}
                     style={{borderBottom: menu === "response" ? "1px solid #000" : "1px solid #ffb600"}}>
                    KẾT QUẢ
                </div>
            </div>
        </div>


        {/*===================== GAME ==================================*/}
        {menu === 'game' && <div>
            <div className={"row"}>
                <div>
                    <div>Ngày</div>
                    <DatePicker
                        selected={timeGame}
                        onChange={(date) => {
                            setTimeGame(date)
                        }}
                        showIcon
                        icon={<MdCalendarToday
                            style={{width: "28px", height: "28px", padding: "4px 0 0 8px"}}/>}/>
                </div>
                {(game === "DANH_DE" || game === "BA_CANG") && <div style={{color: "red"}}>
                    {countdownDe !== null ? (<div style={{display: "flex"}}>
                        <div>Thời gian còn lại:</div>
                        <div>{countdownDe}</div>
                    </div>) : (<div>Loading...</div>)}
                </div>}
                {(game === "LO_XIEN" || game === "LO_TRUOT" || game === "BA_LO") && <div style={{color: "red"}}>
                    {countdown !== null ? (<div style={{display: "flex"}}>
                        <div>Thời gian còn lại:</div>
                        <div>{countdown}</div>
                    </div>) : (<div>Loading...</div>)}
                </div>}

                <div className={"button_type"}>
                    <Button
                        style={{
                            backgroundColor: game === "BA_LO" ? "#1976d2" : "#ccc",
                            color: game === "BA_LO" ? "white" : "#000"
                        }}
                        onClick={() => onClickGame('BA_LO')}
                        className={"button_item"}
                        variant="contained">
                        Bao lô
                    </Button>
                    <Button
                        style={{
                            backgroundColor: game === "LO_XIEN" ? "#1976d2" : "#ccc",
                            color: game === "LO_XIEN" ? "white" : "#000"
                        }}
                        onClick={() => onClickGame('LO_XIEN')}
                        className={"button_item"}
                        variant="contained">
                        Lô xiên
                    </Button>
                    {/*<Button
                        style={{
                            backgroundColor: game === "LO_TRUOT" ? "#1976d2" : "#ccc",
                            color: game === "LO_TRUOT" ? "white" : "#000"
                        }}
                        onClick={() => onClickGame('LO_TRUOT')}
                        className={"button_item"}
                        variant="contained">
                        Lô trượt
                    </Button>*/}
                    <Button
                        style={{
                            backgroundColor: game === "DANH_DE" ? "#1976d2" : "#ccc",
                            color: game === "DANH_DE" ? "white" : "#000"
                        }}
                        onClick={() => {
                            onClickGame('DANH_DE')
                            onClickGameMode("De")
                        }}
                        className={"button_item"}
                        variant="contained">
                        Đánh đề
                    </Button>
                    <Button
                        style={{
                            backgroundColor: game === "BA_CANG" ? "#1976d2" : "#ccc",
                            color: game === "BA_CANG" ? "white" : "#000"
                        }}
                        onClick={() => {
                            onClickGame('BA_CANG')
                            onClickGameMode("BaCang")
                        }}
                        className={"button_item"}
                        variant="contained">
                        Ba Càng
                    </Button>

                </div>

                {/*==================BALO=========================*/}

                {game === "BA_LO" && <div className={"list_function"}>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Lo2So" ? "#1976d2" : "#ccc",
                            color: gameMode === "Lo2So" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Lo2So')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        Lô 2 số
                    </Button>
                    {/*<Button
                        style={{
                            backgroundColor: gameMode === "Lo3So" ? "#1976d2" : "#ccc",
                            color: gameMode === "Lo3So" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Lo3So')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        Lô 3 số
                    </Button>*/}
                </div>}

                {/*==================LO_XIEN=========================*/}

                {game === "LO_XIEN" && <div className={"list_function"}>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Xien2" ? "#1976d2" : "#ccc",
                            color: gameMode === "Xien2" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Xien2')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        XIÊN 2
                    </Button>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Xien3" ? "#1976d2" : "#ccc",
                            color: gameMode === "Xien3" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Xien3')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        XIÊN 3
                    </Button>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Xien4" ? "#1976d2" : "#ccc",
                            color: gameMode === "Xien4" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Xien4')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        XIÊN 4
                    </Button>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Xien5" ? "#1976d2" : "#ccc",
                            color: gameMode === "Xien5" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Xien5')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        XIÊN 5
                    </Button>
                </div>}


                {/*==================LO_TRUOT=========================*/}

                {game === "LO_TRUOT" && <div className={"list_function"}>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Truot10" ? "#1976d2" : "#ccc",
                            color: gameMode === "Truot10" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Truot10')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        TRƯỢT 10
                    </Button>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Truot12" ? "#1976d2" : "#ccc",
                            color: gameMode === "Truot12" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Truot12')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        TRƯỢT 12
                    </Button>
                    <Button
                        style={{
                            backgroundColor: gameMode === "Truot14" ? "#1976d2" : "#ccc",
                            color: gameMode === "Truot14" ? "white" : "#000"
                        }}
                        onClick={() => onClickGameMode('Truot14')}
                        className={"button_item_funtion"}
                        variant="contained"
                    >
                        TRƯỢT 14
                    </Button>
                </div>}

                <div className={"style_input"}>
                    <div style={{margin: "6px 0"}}>Chọn số</div>
                    <TextField
                        value={formatInput(inputValue)}
                        onChange={handleChange}
                        sx={{width: "100%"}}
                        fullWidth
                        multiline
                        InputProps={{
                            rows: 5
                        }}
                        pattern="[0-9 ]*"
                        size={"small"}
                        placeholder={gameMode === "Lo3So" ? placeholder.ba_so : gameMode === "Lo2So" ? placeholder.hai_so : gameMode === "Xien2" ? placeholder.xien2 : gameMode === "Xien3" ? placeholder.xien3 : gameMode === "Xien4" ? placeholder.xien4 : gameMode === "Xien5" ? placeholder.xien5 : gameMode === "Truot10" ? placeholder.truot10 : gameMode === "Truot12" ? placeholder.truot12 : gameMode === "Truot14" ? placeholder.truot14 : gameMode === "De" ? placeholder.de : gameMode === "BaCang" ? placeholder.baCang : ''}
                    />
                </div>
                {error && <div style={{color: "red"}}>{error}</div>}
                <div className={"style_input"}>

                    <div style={{margin: "6px 0"}}>Số điểm trên 1 con</div>
                    <NumericFormat
                        customInput={InputText}
                        value={quantityNumberBet / 1}
                        allowLeadingZeros
                        thousandSeparator=","
                        onValueChange={(e) => onChangeQuantityNumberBetNumberBet(e)}
                        style={{width: "100%"}}
                    />
                    <div style={{
                        margin: "6px 0", color: "red"
                    }}>{gameMode === "Lo3So" ? note2.ba_so : gameMode === "Lo2So" ? note2.hai_so : gameMode === "Xien2" ? note2.xien2 : gameMode === "Xien3" ? note2.xien3 : gameMode === "Xien4" ? note2.xien4 : gameMode === "Xien5" ? note2.xien5 : gameMode === "Truot10" ? note2.truot10 : gameMode === "Truot12" ? note2.truot12 : gameMode === "Truot14" ? note2.truot14 : gameMode === "De" ? note2.de : gameMode === "BaCang" ? note2.baCang : ''}
                    </div>
                </div>
                <div style={{marginBottom: "20px", display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={inputValue.trim().split(',').length === 0 || inputValue === ''}
                        onClick={onBet}
                        className={"button_item"} variant="contained">
                        Xác nhận</Button>
                </div>
                <div style={{
                    margin: "6px 0", color: "red"
                }}>{gameMode === "Lo3So" ? note1.ba_so : gameMode === "Lo2So" ? note1.hai_so : gameMode === "Xien2" ? note1.xien2 : gameMode === "Xien3" ? note1.xien3 : gameMode === "Xien4" ? note1.xien4 : gameMode === "Xien5" ? note1.xien5 : gameMode === "Truot10" ? note1.truot10 : gameMode === "Truot12" ? note1.truot12 : gameMode === "Truot14" ? note1.truot14 : gameMode === "De" ? note1.de : gameMode === "BaCang" ? note1.baCang : ''}
                </div>
                <div className={"style_text_total"}>
                    <div style={{margin: "6px 0"}}>Tổng tiền đánh: {addCommasToNumber(moneyBet * 1000)}</div>
                </div>

                <div className={"style_text_total"}>
                    <div style={{margin: "6px 0"}}>Tổng thắng 1 con: {addCommasToNumber(moneyWin * 1000)}</div>
                </div>

            </div>
        </div>}


        {menu === "history" && <div className="history">
            <div style={{width: "100%"}}>
                <DatePicker
                    selected={timeHistory}
                    onChange={(date) => {
                        // console.log(moment(date).format('YYYYMMDD'))
                        setTimeHistory(date)
                    }}
                    showIcon
                    icon={<MdCalendarToday
                        style={{width: "28px", height: "28px", padding: "4px 0 0 8px"}}/>}/>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button className={"button_item"} onClick={onSearchHistory} variant="contained">Xác nhận</Button>
            </div>
            {history.map((item) => {
                return (<div className={"item_history"}>
                    <div className={"row_item"}>
                        <div>
                                    <span>Loại: <span
                                        style={{fontWeight: "bold"}}>{RenderNameGame(item.gameMode)}</span></span>
                        </div>
                    </div>
                    <div className={"row_item"}>
                        <div style={{width: "100%", display: "flex"}}>Số:
                            <div
                                style={{
                                    fontWeight: "bold", marginLeft: "4px", width: "100%", wordWrap: "break-word"
                                }}>{item.numbers}</div>
                        </div>

                    </div>
                    <div className={"row_item"}>
                        Thời gian cược: {item.createAt}
                    </div>
                    <div className={"row_item"}>
                        <div>
                            Số điểm: <span style={{fontWeight: "bold"}}>{item.point}</span>
                        </div>
                    </div>
                    <div className={"row_item"}>
                        <div>
                            Tiền đánh: <span
                            style={{fontWeight: "bold"}}>{addCommasToNumber(item.moneyBet)}</span>
                        </div>
                    </div>
                    <div className={"row_item"}>
                        <div>
                            Tiền thắng: <span style={{
                            fontWeight: "bold", color: item.moneyWin > 0 ? "green" : 'unset'
                        }}>{addCommasToNumber(item.moneyWin)}</span>
                        </div>
                    </div>
                    <div className={"row_item"}>
                        <span>Trạng thái: {RenderStatus(item.status)}</span>
                    </div>
                    <div className={"row_item"}>
                        <div>
                            Số thắng cược: <div style={{
                            fontWeight: "bold", marginLeft: "4px", width: "100%", color: "green", wordWrap: "break-word"
                        }}>{item.winNumbers}</div>
                        </div>
                    </div>
                </div>)
            })}
        </div>}


        {menu === "response" && <div className={"response"}>
            <div style={{width: "100%"}}>
                <DatePicker
                    selected={timeResponse}
                    onChange={(date) => {
                        // console.log(moment(date).format('YYYYMMDD'))
                        setTimeResponse(date)
                    }}
                    showIcon
                    icon={<MdCalendarToday
                        style={{width: "28px", height: "28px", padding: "4px 0 0 8px"}}/>}/>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Button className={"button_item"} onClick={onSearchResponse} variant="contained">Xác nhận</Button>
            </div>
            {response && response !== "Result = null" && <div className={"response_row"}>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải ĐB</div>
                    <div className={"number_response"} style={{color: "red"}}>{response.resultSp}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Nhất</div>
                    <div className={"number_response"}>{response.result1}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Nhì</div>
                    <div className={"number_response"}>{response.result2}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Ba</div>
                    <div className={"number_response"}>{response.result3}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Tư</div>
                    <div className={"number_response"}>{response.result4}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Năm</div>
                    <div className={"number_response"}>{response.result5}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Sáu</div>
                    <div className={"number_response"}>{response.result6}</div>
                </div>
                <div className={"item_response"}>
                    <div className={"title_response"}>Giải Bảy</div>
                    <div className={"number_response"}>{response.result7}</div>
                </div>
            </div>}
            {response && response === "Result = null" &&
                <div style={{textAlign: "center", fontSize: "20px", marginTop: "20px", color: "red"}}>
                    Chưa có kết quả ngày bạn chọn
                </div>}
        </div>}
    </Container>);
};

export default QuaySo;


function addCommasToNumber(number) {
    if (number.toString().includes(".")) {
        return number.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

const placeholder = {
    "hai_so": "Nhập một hoặc nhiều số có 2 chữ số. \nVí dụ bạn muốn đánh 3 số 01 02 03 nhập như sau: 01, 02, 03",
    "ba_so": "Nhập một hoặc nhiều số có 3 chữ số. " + "\nVí dụ bạn muốn đánh 3 số 001 002 003 nhập như sau: 001, 002, 003",
    "xien2": "Nhập một hoặc nhiều bộ 2 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô xiên 2 số 01 02 và 03 04 nhập như sau: 01-02, 03-04",
    "xien3": "Nhập một hoặc nhiều bộ 3 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô xiên 3 số 01 02 03 và 04 05 06 nhập như sau: 01-02-03, 04-05-06",
    "xien4": "Nhập một hoặc nhiều bộ 4 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô xiên 4 số 01 02 03 04 và 05 06 07 08 nhập như sau: 01-02-03-04, 05-06-07-08",
    "xien5": "Nhập một hoặc nhiều bộ 5 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô xiên 5 số 01 02 03 04 05 và 06 07 08 09 10 nhập như sau: 01-02-03-04-05, 06-07-08-09-10",
    "truot10": "Nhập một hoặc nhiều bộ 10 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô trượt 10 số 01 02 03 04 05 06 07 08 09 10 nhập như sau: 01-02-03-04-05-06-07-08-09-10",
    "truot12": "Nhập một hoặc nhiều bộ 14 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô trượt 10 số 01 02 03 04 05 06 07 08 09 10 11 12 nhập như sau: 01-02-03-04-05-06-07-08-09-10-11-12",
    "truot14": "Nhập một hoặc nhiều bộ 14 số có 2 chữ số. " + "\nVí dụ bạn muốn đánh lô trượt 10 số 01 02 03 04 05 06 07 08 09 10 11 12 13 14 nhập như sau: 01-02-03-04-05-06-07-08-09-10-11-12-13-14",
    "de": "Nhập một hoặc nhiều số có 2 chữ số. " + "\nVí dụ bạn muốn đánh đề số 01 02 03 nhập như sau: 01, 02, 03",
    "baCang": "Nhập một hoặc nhiều số có 3 chữ số. " + "\nVí dụ bạn muốn đánh ba càng số 123 456 nhập như sau: 123, 456",
}

const note1 = {
    "hai_so": "Đánh 2 chữ số cuối trong lô 27 giải. Thắng gấp 80 lần số điểm đánh, nếu số đó về N lần thì tính kết quả x N lần. Ví dụ: đánh lô 79 - 1 con 1 điểm, Tổng thanh toán: 1 điểm x 21.5 = 21.5k. Nếu trong lô có 2 chữ số cuối là 79 thì Tiền thắng: 1 điểm x 80 = 80k, nếu có N lần 2 chữ số cuối là 79 thì Tiền thắng là: 1 điểm x 80 x N",
    "ba_so": "Đánh 3 chữ số cuối trong lô 23 giải. Thắng gấp 700 lần số điểm đánh, nếu số đó về N lần thì tính kết quả x N lần. Ví dụ: đánh lô 789 - 1 con 1 điểm, Tổng thanh toán: 1 điểm x 21.5 = 21.5k. Nếu trong lô có 3 chữ số cuối là 789 thì Tiền thắng: 1k x 700 = 700k, nếu có N lần 3 chữ số cuối là 789 thì Tiền thắng là: 1 điểm x 700 x N",
    "xien2": "Xiên 2 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 15 lần. Ví dụ : đánh 10k cho xiên 11+13, Tổng thanh toán: 10k. Nếu trong lô có 2 chữ số cuối là 11, 13 thì Tiền thắng: 10k x 15 = 150k",
    "xien3": "Xiên 3 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 43 lần. Ví dụ : đánh 10k cho xiên 11+13+15, Tổng thanh toán: 10k. Nếu trong lô có 2 chữ số cuối là 11,13,15 thì Tiền thắng: 10k x 43 = 430k",
    "xien4": "Xiên 4 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 100 lần. Ví dụ : đánh 10k cho xiên 11+13+15+20, Tổng thanh toán: 10k. Nếu trong lô có 2 chữ số cuối là 11,13,15,20 thì Tiền thắng: 10k x 100 = 1000k",
    "xien5": "Xiên 5 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 300 lần. Ví dụ : đánh 10k cho xiên 11+13+15+20+54, Tổng thanh toán: 10k. Nếu trong lô có 2 chữ số cuối là 11,13,15,20,54 thì Tiền thắng: 10k x 300 = 3000k",
    "truot10": "Trượt 10 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 11 lần. Ví dụ : đánh 10k cho lô trượt 01+02+03+04+05+06+07+08+09+10, Tổng thanh toán: 10k. Nếu trong lô không có 2 chữ số cuối là 01,02,03,04,05,06,07,08,09,10 thì Tiền thắng: 10k x 11 = 110k",
    "truot12": "Trượt 12 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 41 lần. Ví dụ : đánh 10k cho lô trượt 01+02+03+04+05+06+07+08+09+10+11+12, Tổng thanh toán: 10k. Nếu trong lô không có 2 chữ số cuối là 01,02,03,04,05,06,07,08,09,10,11,12 thì Tiền thắng: 10k x 41 = 410k",
    "truot14": "Trượt 14 của 2 chữ số cuối trong lô 27 giải. Thắng gấp 80 lần. Ví dụ : đánh 10k cho lô trượt 01+02+03+04+05+06+07+08+09+10+11+12+13+14, Tổng thanh toán: 10k. Nếu trong lô không có 2 chữ số cuối là 01,02,03,04,05,06,07,08,09,10,11,12,13,14 thì Tiền thắng: 10k x 80 = 800k",
    "de": "Đánh 2 chữ số cuối trong giải ĐB. Thắng gấp 85 lần. Ví dụ: đánh 10k cho số 79. Tổng thanh toán: 10k. Nếu giải ĐB là xxx79 thì Tiền thắng: 10k x 85 = 850k",
    "baCang": "Đánh 3 chữ số cuối trong giải ĐB. Thắng gấp 450 lần. Ví dụ: đánh 10k cho số 479. Tổng thanh toán: 10k. Nếu giải ĐB là xx479 thì Tiền thắng: 10k x 450 = 4500k",
}
const note2 = {
    "hai_so": "1 điểm  = 21.5k - Đánh tối thiểu 1 điểm",
    "ba_so": "1 điểm  = 21.5k - Đánh tối thiểu 1 điểm",
    "xien2": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "xien3": "1 điểm  = 1kk - Đánh tối thiểu 10 điểm",
    "xien4": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "xien5": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "truot10": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "truot12": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "truot14": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "de": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
    "baCang": "1 điểm  = 1k - Đánh tối thiểu 10 điểm",
}

function updateTimeUsingApp() {
    let date = new Date()
    let timestamp = date.getTime()
    localStorage.setItem("time", timestamp)
}
